import { useEffect, useState } from 'react';
import { fetchSeason } from '@queries/Season';
import { fetchSeasonBets } from '@queries/Bet';
import { useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Loading } from '@components';
import loadingSvg from '@assets/loading.svg';
import _ from 'lodash';

function Ranking() {
  const [logoPartner, setLogoPartner] = useState('');
  const [isReady, setIsReady] = useState(false);
  const [bets, setBets] = useState([]);
  const [loges, setLoges] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { seasonId } = location.state;

  // Get global season parameters
  useQuery(['season'], () => fetchSeason(seasonId), {
    onSuccess: (data) => {
      // Set Match ID
      setLogoPartner(data?.data?.logo_sponsor?.url);

      // Set Font
      const fontFace = new FontFace('Custom', `url(${data?.data?.club?.font?.url})`);
      document.fonts.add(fontFace);
      fontFace.load();
    }
  });

  // Get bets according to current season parameters
  useQuery([seasonId, 'seasonBets'], () => fetchSeasonBets(seasonId), {
    onSuccess: (data) => {
      setBets(data?.data);
      setIsReady(true);
    },
    enabled: !!logoPartner
  });

  useEffect(() => {
    const groupLoges = _.groupBy(bets, (o) => o?.loge?.id);
    setLoges(
      Object.keys(groupLoges).map((key) => ({
        id: key,
        name: groupLoges?.[key]?.[0]?.loge?.name,
        img: groupLoges?.[key]?.[0]?.loge?.logo?.url,
        score: groupLoges?.[key]
          ?.map((p) => p?.reward)
          ?.filter((p) => p != null)
          ?.reduce((acc, cur) => acc + cur, 0)
      }))
    );
  }, [bets]);

  return isReady ? (
    <div
      className="p-4 bg-white flex flex-col h-screen max-w-prose text-xl"
      style={{ fontFamily: 'Custom' }}>
      <p
        className="font-montserrat"
        onClick={() => navigate(`/?${params.toString()}`, { state: { seasonId } })}>
        Back
      </p>
      <div>
        <p className="text-5xl text-monaco mb-3 font-bold mt-10">CLASSEMENT</p>
        <img src={logoPartner} />
      </div>
      <div className="flex flex-col w-full font-montserrat mt-10">
        {loges
          .sort((a, b) => b?.score - a?.score)
          .map((o, index) => (
            <div
              key={index}
              className="flex justify-between items-center h-20 border-t-2 border-gray-300 last:border-b-2 hover:bg-gray-50 py-2"
              onClick={() =>
                navigate(`/result-loge?${params.toString()}`, {
                  state: { seasonId, logeId: o?.id }
                })
              }>
              <p className="shrink-0">{o?.name}</p>
              <img src={o?.img} className="object-scale-down h-full w-1/4 mx-2" />
              <p className="font-bold shrink-0">{o?.score} pts</p>
            </div>
          ))}
      </div>
    </div>
  ) : (
    <Loading src={loadingSvg} />
  );
}

export default Ranking;
