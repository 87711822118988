import { useEffect, useState } from 'react';
import { fetchSeason } from '@queries/Season';
import { fetchSeasonBets } from '@queries/Bet';
import { useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import loadingSvg from '@assets/loading.svg';
import { Loading } from '@components';

function ResultBet() {
  const [logoPartner, setLogoPartner] = useState('');
  const [isReady, setIsReady] = useState(false);
  const [bets, setBets] = useState([]);
  const [bet, setBet] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { seasonId, logeId, matchId, betId } = location.state;

  // Get global season parameters
  useQuery(['season'], () => fetchSeason(seasonId), {
    onSuccess: (data) => {
      // Set Match ID
      setLogoPartner(data?.data?.logo_sponsor?.url);

      // Set Font
      const fontFace = new FontFace('Custom', `url(${data?.data?.club?.font?.url})`);
      document.fonts.add(fontFace);
      fontFace.load();
    }
  });

  // Get bets according to current season parameters
  useQuery([seasonId, 'seasonBets'], () => fetchSeasonBets(seasonId), {
    onSuccess: (data) => {
      setBets(data?.data);
      setIsReady(true);
    },
    enabled: !!logoPartner
  });

  useEffect(() => {
    setBet(bets?.filter((o) => o?.id == betId)?.[0]);
  }, [bets]);

  const is1x2 = bet?.reward == 4 || bet?.reward == 9 || bet?.reward == 11 || bet?.reward == 16;
  const isExactMatch =
    bet?.reward == 8 || bet?.reward == 13 || bet?.reward == 11 || bet?.reward == 16;
  const isButeur = bet?.reward == 6 || bet?.reward == 9 || bet?.reward == 13 || bet?.reward == 16;

  return isReady ? (
    <div
      className="p-4 bg-white flex flex-col h-screen max-w-prose justify-between text-xl"
      style={{ fontFamily: 'Custom' }}>
      <p
        className="font-montserrat"
        onClick={() =>
          navigate(`/result-bet?${params.toString()}`, { state: { seasonId, logeId, matchId } })
        }>
        Back
      </p>
      <div>
        <p className="text-5xl mb-3 mt-10 text-monaco font-bold">RESULTATS</p>
        <img src={logoPartner} />
      </div>
      <p className="font-montserrat shrink-0 font-bold text-monaco">{bet?.name}</p>
      <div className="flex flex-col h-3/5 w-full font-montserrat">
        <div className="flex justify-between items-center h-20 border-t-2 border-gray-300 hover:bg-gray-50">
          <p className="shrink-0">Participation</p>
          <p className="font-bold shrink-0">1 pts</p>
        </div>
        <div className="flex justify-between items-center h-20 border-t-2 border-gray-300 hover:bg-gray-50">
          <p className="shrink-0">1x2</p>
          <p className="font-bold shrink-0">{is1x2 ? 3 : 0} pts</p>
        </div>
        <div className="flex justify-between items-center h-20 border-t-2 border-gray-300 hover:bg-gray-50">
          <p className="shrink-0">Score exact</p>
          <p className="font-bold shrink-0">{isExactMatch ? 7 : 0} pts</p>
        </div>
        <div className="flex justify-between items-center h-20 border-t-2 border-gray-300 hover:bg-gray-50">
          <p className="shrink-0">Buteur</p>
          <p className="font-bold shrink-0">{isButeur ? 5 : 0} pts</p>
        </div>
        <div className="flex justify-between items-center h-20 border-t-2 border-black hover:bg-gray-50">
          <p className="shrink-0 font-bold">TOTAL</p>
          <p className="font-bold shrink-0">{bet?.reward} pts</p>
        </div>
      </div>
    </div>
  ) : (
    <Loading src={loadingSvg} />
  );
}

export default ResultBet;
