import * as queries from '@queries/utils';
import qs from 'qs';

export const fetchSeasonBets = (id) =>
  queries.getQuery(
    '/api/bets',
    qs.stringify(
      {
        populate: ['match.season', 'loge.logo', 'match.club1.logo', 'match.club2.logo'],
        filters: {
          match: {
            season: {
              id
            }
          }
        }
      },
      {
        encodeValuesOnly: true // prettify URL
      }
    ),
    {}
  );

export const fetchSeasonBetsByLoge = (id, matchId) =>
  queries.getQuery(
    '/api/bets',
    qs.stringify(
      {
        populate: ['match.season', 'loge.logo', 'match.club1.logo', 'match.club2.logo'],
        filters: {
          loge: {
            id
          },
          match: {
            id: matchId
          }
        }
      },
      {
        encodeValuesOnly: true // prettify URL
      }
    ),
    {}
  );

export const fetchBets = () =>
  queries.getQuery(
    '/api/bets',
    qs.stringify(
      {
        populate: ['match.season', 'loge.logo', 'match.club1.logo', 'match.club2.logo', 'player']
      },
      {
        encodeValuesOnly: true // prettify URL
      }
    )
  );

export const createBet = (data) => queries.postQuery('/api/bets', data, {});
export const updateBet = (data) => queries.putQuery('/api/bets', data);
export const deleteBet = (data) => queries.deleteQuery('/api/bets', data);
