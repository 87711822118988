import { useState } from 'react';
import { fetchSeason } from '@queries/Season';
import { fetchDistributionBet, fetchMostPredicted, fetchWinner } from '@queries/Statistics';
import { fetchPlayer } from '@queries/Players';
import { useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Loading, Alert } from '@components';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import loadingSvg from '@assets/loading.svg';

function Statistics() {
  const [mostPredicted, setMostPredicted] = useState({});
  const [distributionBet, setDistributionBet] = useState({});
  const [winner, setWinner] = useState({});
  const [idMatch, setIdMatch] = useState(null);
  const [logoPartner, setLogoPartner] = useState('');
  const [player, setPlayer] = useState({});
  const [isReady, setIsReady] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { seasonId, warning } = location.state;

  // Get global season parameters
  useQuery(['season'], () => fetchSeason(seasonId), {
    onSuccess: (data) => {
      // Set Match ID
      setIdMatch(data?.data?.current_match?.id);
      setLogoPartner(data?.data?.logo_sponsor?.url);

      // Set Font
      const fontFace = new FontFace('Custom', `url(${data?.data?.club?.font?.url})`);
      document.fonts.add(fontFace);
      fontFace.load();
    }
  });

  // Set Most Predicted
  useQuery([idMatch, 'mostPredicted'], () => fetchMostPredicted(idMatch), {
    onSuccess: (data) => setMostPredicted(data)
  });

  // Set Distribution Bet
  useQuery([idMatch, 'distributionBet'], () => fetchDistributionBet(idMatch), {
    onSuccess: (data) => setDistributionBet(data)
  });

  // Set Winner
  useQuery([idMatch, 'winner'], () => fetchWinner(idMatch), {
    onSuccess: (data) => setWinner(data)
  });

  // Get Player
  useQuery([idMatch, mostPredicted?.player, 'player'], () => fetchPlayer(mostPredicted?.player), {
    onSuccess: (data) => {
      setPlayer(data?.data);
      setIsReady(true);
    },
    onError: (e) => {
      console.error(e);
      setIsReady(true);
    },
    retry: false
  });

  // console.log(mostPredicted, winner, distributionBet, isReady);

  return isReady ? (
    <div
      className="p-4 bg-white flex flex-col h-screen max-w-prose justify-between text-xl"
      style={{ fontFamily: 'Custom' }}>
      <p
        className="grow font-montserrat"
        onClick={() => navigate(`/?${params.toString()}`, { state: { seasonId } })}>
        Back
      </p>
      <div className="grow">
        <p className="text-5xl mb-3 text-monaco font-bold">STATISTIQUES</p>
        <img src={logoPartner} />
      </div>
      {warning && (
        <Alert color="blue" icon={ExclamationCircleIcon} className="mb-6 font-montserrat">
          Vous avez atteint le nombre maximal de 10 pronostics par loge.
        </Alert>
      )}
      <div className="flex flex-col justify-between grow h-3/5">
        <div>
          <p className="font-montserrat text-monaco font-bold">GAGNANT DU JOUR :</p>
          {winner?.reward ? (
            <div>
              <p className="font-montserrat">
                Gagnant : <span className="font-bold">{winner?.name}</span>
              </p>
              <p className="font-montserrat">
                Loge : <span className="font-bold">{winner?.loge?.name}</span>
              </p>
              <p className="font-montserrat">
                Score : <span className="font-bold">{winner?.reward}</span>
              </p>
            </div>
          ) : (
            <p className="font-montserrat">Le gagnant du jour sera affiché ici à la fin du match</p>
          )}
        </div>
        <div className="border-t-2 border-gray-300 rounded shrink my-10"></div>
        <div className="grow">
          <p className="font-montserrat text-monaco font-bold">DISTRIBUTION DES PARIS :</p>
          <p className="font-montserrat">
            Victoire : <span className="font-bold">{Math.round(distributionBet?.v * 100)}</span>%
          </p>
          <p className="font-montserrat">
            Nul : <span className="font-bold">{Math.round(distributionBet?.n * 100)}</span>%
          </p>
          <p className="font-montserrat">
            Défaite : <span className="font-bold">{Math.round(distributionBet?.l * 100)}</span>%
          </p>
        </div>
        <div className="grow">
          <p className="font-montserrat text-monaco font-bold">LE PLUS PARIÉ :</p>
          <p className="font-montserrat">
            Score :{' '}
            <span className="font-bold">
              {mostPredicted?.bet_club_1} - {mostPredicted?.bet_club_2}
            </span>
          </p>
          <p className="font-montserrat">
            Buteur : <span className="font-bold">{player?.name ?? '-'}</span>
          </p>
        </div>
      </div>
    </div>
  ) : (
    <Loading src={loadingSvg} />
  );
}

export default Statistics;
