import * as queries from '@queries/utils';
import qs from 'qs';
const query = qs.stringify(
  {
    populate: ['logo']
  },
  {
    encodeValuesOnly: true // prettify URL
  }
);

export const fetchLoge = (id) => queries.getQuery('/api/loges/' + id, query, {});
export const fetchLoges = () => queries.getQuery('/api/loges', query, {});
export const createLoge = (data) => queries.postQuery('/api/loges', data);
export const updateLoge = (data) => queries.putQuery('/api/loges', data);
export const deleteLoge = (data) => queries.deleteQuery('/api/loges', data);
