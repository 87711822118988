import { useEffect, useState } from 'react';
import { fetchSeason } from '@queries/Season';
import { fetchSeasonBets } from '@queries/Bet';
import { useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Loading } from '@components';
import loadingSvg from '@assets/loading.svg';
import _ from 'lodash';

function ResultBet() {
  const [logoPartner, setLogoPartner] = useState('');
  const [isReady, setIsReady] = useState(false);
  const [bets, setBets] = useState([]);
  const [match, setMatch] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { seasonId, logeId, matchId } = location.state;

  // Get global season parameters
  useQuery(['season'], () => fetchSeason(seasonId), {
    onSuccess: (data) => {
      // Set Match ID
      setLogoPartner(data?.data?.logo_sponsor?.url);

      // Set Font
      const fontFace = new FontFace('Custom', `url(${data?.data?.club?.font?.url})`);
      document.fonts.add(fontFace);
      fontFace.load();
    }
  });

  // Get bets according to current season parameters
  useQuery([seasonId, 'seasonBets'], () => fetchSeasonBets(seasonId), {
    onSuccess: (data) => {
      setBets(data?.data);
      setIsReady(true);
    },
    enabled: !!logoPartner
  });

  useEffect(() => {
    const groupBets = _.groupBy(bets, (o) => o?.loge?.id);
    const groupMatch = _.groupBy(groupBets?.[logeId], (o) => o?.match?.id);
    setMatch({
      imgClub1: groupMatch?.[matchId]?.[0]?.match?.club1?.logo?.url,
      imgClub2: groupMatch?.[matchId]?.[0]?.match?.club2?.logo?.url,
      bets: groupMatch?.[matchId]
    });
  }, [bets]);

  return isReady ? (
    <div
      className="p-4 bg-white flex flex-col h-screen max-w-prose justify-between text-xl"
      style={{ fontFamily: 'Custom' }}>
      <p
        className="font-montserrat"
        onClick={() =>
          navigate(`/result-loge?${params.toString()}`, { state: { seasonId, logeId } })
        }>
        Back
      </p>
      <div>
        <p className="text-5xl mb-3 mt-10 text-monaco font-bold">RESULTATS</p>
        <img src={logoPartner} />
        <div className="flex w-full justify-center mt-4 mb-4">
          <img src={match?.imgClub1} className=" object-scale-down max-h-24 mr-2" />
          <img src={match?.imgClub2} className=" object-scale-down max-h-24  " />
        </div>
      </div>
      <div className="flex flex-col h-3/5 w-full font-montserrat">
        {match?.bets
          ?.sort((a, b) => b?.reward - a?.reward)
          ?.map((o, index) => (
            <div
              key={index}
              style={{ minHeight: 78 }}
              className="flex justify-between items-center h-20 border-t-2 border-gray-300 last:border-b-2 hover:bg-gray-50"
              onClick={() => {
                if (o?.reward) {
                  navigate(`/result-detail-bet?${params.toString()}`, {
                    state: { seasonId, logeId: logeId, matchId, betId: o?.id }
                  });
                }
              }}>
              <p className="shrink-0">{o?.name}</p>
              <p className="font-bold shrink-0">{o?.reward ? o?.reward + ' pts' : 'En cours'}</p>
            </div>
          ))}
      </div>
    </div>
  ) : (
    <Loading src={loadingSvg} />
  );
}

export default ResultBet;
