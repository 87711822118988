import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { createBet } from '@queries/Bet';
import { fetchSeason } from '@queries/Season';
import { Loading } from '@components';
import loadingSvg from '@assets/loading.svg';

function Pronostic() {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const [seasons, setSeasons] = useState({});
  const [logoPartner, setLogoPartner] = useState('');
  const [isReady, setIsReady] = useState({});
  const [isAccepted, setIsAccepted] = useState(false);
  const [logoClubs, setLogoClubs] = useState({
    logoClub1: '',
    logoClub2: ''
  });
  // Set the bet
  const [bet, setBet] = useState({});

  // Get from params
  const seasonId = params.get('seasonId');
  const logeId = params.get('logeId');

  // Get global season parameters
  useQuery(['season'], () => fetchSeason(seasonId), {
    onSuccess: (data) => {
      // Set Seasons
      setSeasons(data?.data);
      setLogoPartner(data?.data?.logo_sponsor?.url);

      // Set Font
      const fontFace = new FontFace('Custom', `url(${data?.data?.club?.font?.url})`);
      document.fonts.add(fontFace);
      fontFace.load();
      setLogoClubs({
        logoClub1: data?.data?.current_match?.club1?.logo?.url,
        logoClub2: data?.data?.current_match?.club2?.logo?.url
      });

      // Set bet
      setBet({
        name: '',
        match: data?.data?.current_match?.id,
        bet_club_1: '',
        bet_club_2: '',
        player: null,
        loge: logeId
      });

      // Set isReady
      setIsReady(true);
    }
  });

  const addPlayers = () => [
    {
      name: seasons?.current_match?.club1?.name,
      number: 'Club',
      disabled: true
    },
    ...seasons.current_match.club1.players,
    {
      name: seasons?.current_match?.club2?.name,
      number: 'Club',
      disabled: true
    },
    ...seasons.current_match.club2.players
  ];

  const createBetMutation = useMutation((data) => createBet(data));
  // Concat players list
  const players =
    seasons?.current_match?.club1?.players?.length > 0 &&
    seasons?.current_match?.club2?.players?.length > 0
      ? addPlayers()
      : [];

  const handleChange = (event) => {
    setBet({ ...bet, [event.target.name]: event.target.value });
  };

  const submit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const uploadFormData = new FormData();

    let newBet = Object.assign({}, bet);
    if (bet.bet_club_1 === '0' && bet.bet_club_2 === '0') newBet.player = null;
    try {
      uploadFormData.append('data', JSON.stringify(newBet));
      await createBetMutation.mutateAsync([uploadFormData]);
      navigate(`/validation?${params.toString()}`, { state: { seasonId } });
    } catch (e) {
      navigate(`/statistics?${params.toString()}`, { state: { seasonId, warning: true } });
      console.error(e);
    }
  };

  return isReady ? (
    <div className="p-4 bg-white flex flex-col h-screen max-w-prose justify-between text-xl font-montserrat">
      <p onClick={() => navigate(`/?${params.toString()}`, { state: { seasonId } })}>Back</p>
      <div>
        <img className="mt-10" src={logoPartner} />
      </div>
      <div className="flex w-full justify-center mt-4 mb-4">
        <img className="h-28" src={logoClubs.logoClub1} />
        <img className="h-28" src={logoClubs.logoClub2} />
      </div>
      <div className="flex flex-col justify-between h-3/5">
        <form onSubmit={submit}>
          <div>
            <label htmlFor="name" className="mb-2 block text-xl font-medium text-gray-700">
              Prénom
            </label>
            <div className="mb-4">
              <input
                type="name"
                name="name"
                id="name"
                value={bet.name}
                onChange={(e) => handleChange(e)}
                className="shadow-sm h-12 p-2 block w-full sm:text-sm border-2 rounded-md border-gray-300 outline-none focus:border-red-700"
                placeholder="Entrez votre prenom."
                required={true}
              />
            </div>
            <label htmlFor="score" className="mb-2 block text-xl font-medium text-gray-700">
              Score
            </label>
            <div className="flex mb-4">
              <input
                type="number"
                name="bet_club_1"
                id="bet_club_1"
                value={bet.bet_club_1}
                onChange={(e) => handleChange(e)}
                className="p-2 h-12 shadow-sm block w-full sm:text-sm border-2 border-gray-300 rounded-md outline-none focus:border-red-700"
                pattern="[0-9]+"
                required={true}
              />
              <label
                htmlFor="score"
                className="mr-4 ml-4 block text-xl font-medium text-gray-700 self-center">
                à
              </label>
              <input
                type="number"
                name="bet_club_2"
                id="bet_club_2"
                value={bet.bet_club_2}
                onChange={(e) => handleChange(e)}
                className="p-2 h-12 shadow-sm block w-full sm:text-sm border-2 border-gray-300 rounded-md outline-none focus:border-red-700"
                pattern="[0-9]+"
                required={true}
              />
            </div>
            <label htmlFor="location" className="block text-xl mb-2 font-medium text-gray-700">
              Buteur
            </label>
            <select
              disabled={bet.bet_club_1 === '0' && bet.bet_club_2 === '0' ? true : false}
              value={bet.bet_club_1 === '0' && bet.bet_club_2 === '0' ? '' : bet.player}
              required={bet.bet_club_1 !== '0' || bet.bet_club_2 !== '0'}
              id="location"
              name="player"
              onChange={(e) => handleChange(e)}
              className="mb-4 h-12 bg-white block w-full p-3 text-base border-2 border-gray-300 sm:text-sm rounded-md outline-none focus:border-red-700 disabled:bg-gray-200">
              <option value="" selected hidden></option>
              {players.length &&
                players.map((e, index) => (
                  <option value={e.id} key={index} disabled={e?.disabled ? true : false}>
                    {e.number} - {e.name}
                  </option>
                ))}
            </select>
            <div className="ml-1 mb-4 relative flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="offers"
                  aria-describedby="offers-description"
                  name="offers"
                  type="checkbox"
                  required={true}
                  value={isAccepted}
                  onChange={(e) => setIsAccepted(e.currentTarget.checked)}
                  className="h-4 w-4 rounded"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="offers" id="offers-description" className="text-gray-500">
                  J&apos;ai lu et accepte le règlement du concours
                </label>
              </div>
            </div>
            <div className="flex justify-center mb-6">
              <button
                type="submit"
                disabled={!isAccepted}
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-700 hover:bg-red-800 focus:outline-none w-full justify-center disabled:bg-gray-400">
                PARTICIPER
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  ) : (
    <Loading src={loadingSvg} />
  );
}

export default Pronostic;
