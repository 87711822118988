const Loading = (props) => {
  const { src = <></>, className } = props;

  return (
    <div
      className="bg-white flex justify-center items-center h-screen items-center"
      style={{ height: '100vh' }}>
      <img src={src} className={className} />
    </div>
  );
};

export default Loading;
