import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  HomePage,
  Pronostic,
  Statistics,
  Validation,
  Ranking,
  ResultBet,
  ResultLoge,
  ResultDetailBet
} from '@pages';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="pronostic" element={<Pronostic />} />
          <Route path="statistics" element={<Statistics />} />
          <Route path="validation" element={<Validation />} />
          <Route path="ranking" element={<Ranking />} />
          <Route path="result-loge" element={<ResultLoge />} />
          <Route path="result-bet" element={<ResultBet />} />
          <Route path="result-detail-bet" element={<ResultDetailBet />} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
