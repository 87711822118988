import * as queries from '@queries/utils';
import qs from 'qs';
const query = qs.stringify(
  {
    populate: ['club.logo']
  },
  {
    encodeValuesOnly: true // prettify URL
  }
);

export const fetchPlayer = (id) => queries.getQuery('/api/players/' + id, query, {});
export const fetchPlayers = () => queries.getQuery('/api/players', query, {});
export const createPlayer = (data) => queries.postQuery('/api/players', data);
export const updatePlayer = (data) => queries.putQuery('/api/players', data);
export const deletePlayer = (data) => queries.deleteQuery('/api/players', data);
