import { useEffect, useState } from 'react';
import { fetchSeason } from '@queries/Season';
import { fetchSeasonBets } from '@queries/Bet';
import { useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Loading } from '@components';
import loadingSvg from '@assets/loading.svg';
import _ from 'lodash';

function ResultLoge() {
  const [logoPartner, setLogoPartner] = useState('');
  const [isReady, setIsReady] = useState(false);
  const [bets, setBets] = useState([]);
  const [loge, setLoge] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { seasonId, logeId } = location.state;

  // Get global season parameters
  useQuery(['season'], () => fetchSeason(seasonId), {
    onSuccess: (data) => {
      // Set Match ID
      setLogoPartner(data?.data?.logo_sponsor?.url);

      // Set Font
      const fontFace = new FontFace('Custom', `url(${data?.data?.club?.font?.url})`);
      document.fonts.add(fontFace);
      fontFace.load();
    }
  });

  // Get bets according to current season parameters
  useQuery([seasonId, 'seasonBets'], () => fetchSeasonBets(seasonId), {
    onSuccess: (data) => {
      setBets(data?.data);
      setIsReady(true);
    },
    enabled: !!logoPartner
  });

  useEffect(() => {
    const groupBets = _.groupBy(bets, (o) => o?.loge?.id);
    const groupMatch = _.groupBy(groupBets?.[logeId], (o) => o?.match?.id);
    setLoge({
      img: groupBets?.[logeId]?.[0]?.loge?.logo?.url,
      name: groupBets?.[logeId]?.[0]?.loge?.name,
      matches: Object.keys(groupMatch).map((key) => ({
        id: key,
        imgClub1: groupMatch?.[key]?.[0]?.match?.club1?.logo?.url,
        imgClub2: groupMatch?.[key]?.[0]?.match?.club2?.logo?.url,
        score: groupMatch?.[key]
          ?.map((p) => p?.reward)
          ?.filter((p) => p != null)
          ?.reduce((acc, cur) => acc + cur, 0)
      }))
    });
  }, [bets]);

  return isReady ? (
    <div
      className="p-4 bg-white flex flex-col h-screen max-w-prose justify-between text-xl"
      style={{ fontFamily: 'Custom' }}>
      <p
        className="font-montserrat"
        onClick={() => navigate(`/ranking?${params.toString()}`, { state: { seasonId } })}>
        Back
      </p>
      <div>
        <p className="text-5xl mb-3 mt-10 text-monaco font-bold">RESULTATS</p>
        <img src={logoPartner} />
        <p className="mt-10 text-monaco font-bold">{loge?.name}</p>
        <div className="flex justify-center">
          <img src={loge?.img} className=" object-scale-down max-h-20  max-w-40 mb-6 mt-1" />
        </div>
      </div>
      <div className="flex flex-col h-3/5 w-full font-montserrat">
        {loge?.matches?.map((o, index) => (
          <div
            key={index}
            style={{ minHeight: 78 }}
            className="flex justify-between items-center h-20 border-t-2 border-gray-300 last:border-b-2 hover:bg-gray-50 py-2"
            onClick={() =>
              navigate(`/result-bet?${params.toString()}`, {
                state: { seasonId, logeId: logeId, matchId: o?.id }
              })
            }>
            <div className="flex items-center h-full ">
              <img src={o?.imgClub1} className="object-scale-down h-14" />
              <img src={o?.imgClub2} className="object-scale-down h-14 ml-2" />
            </div>
            <p className="font-bold shrink-0">{o?.score} pts</p>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <Loading src={loadingSvg} />
  );
}

export default ResultLoge;
