import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchSeason } from '@queries/Season';
import { fetchSeasonBetsByLoge } from '@queries/Bet';
import { fetchLoge } from '@queries/Loge';
import moment from 'moment';
import '@root/locale';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Loading } from '@components';
import loadingSvg from '@assets/loading.svg';

function HomePage() {
  const [timer, setTimer] = useState({});
  const [loge, setLoge] = useState({});
  const [seasons, setSeasons] = useState({});
  const [isTiming, setIsTiming] = useState(false);
  const [betsLength, setBetsLength] = useState(null);
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const seasonId = params.get('seasonId');
  const logeId = params.get('logeId');
  const eventName = params.get('eventName');
  const label = params.get('label');

  const updateTimer = (seasons) => {
    const startDate = moment(seasons?.current_match?.start_date);
    const timeTemp = moment.duration(startDate.diff(moment()));
    const timezoneDiff = startDate.utcOffset() - moment().utcOffset();

    setIsTiming(timeTemp.subtract(timezoneDiff, 'm').asMilliseconds() >= 0);
    setTimer(timeTemp);
  };

  useQuery(['loge', logeId], () => fetchLoge(logeId), {
    onSuccess: (data) => {
      setLoge(data?.data);
    }
  });

  useQuery(
    ['bets', logeId, seasons?.current_match?.id],
    () => fetchSeasonBetsByLoge(logeId, seasons?.current_match?.id),
    {
      onSuccess: (data) => {
        setBetsLength(data?.data?.length);
      }
    }
  );

  useQuery(['season', seasonId], () => fetchSeason(seasonId), {
    onSuccess: (data) => {
      setSeasons(data?.data);

      // Set inital time difference
      setIsTiming(!data?.data?.current_match?.hasStarted);
      updateTimer(data?.data);

      // Set font
      const fontFace = new FontFace('Custom', `url(${data?.data?.club?.font?.url})`);
      document.fonts.add(fontFace);
      fontFace.load();
    }
  });

  // Create a local setInterval mechanism
  useEffect(() => {
    const myInterval = setInterval(function () {
      if (isTiming) updateTimer(seasons);
    }, 1000 * 30);

    return () => clearInterval(myInterval);
  }, [seasons, isTiming]);

  return (
    <>
      {Object.keys(seasons).length !== 0 && Object.keys(loge).length !== 0 ? (
        <div
          role="status"
          className="bg-white flex flex-col justify-around items-center h-screen"
          style={{ fontFamily: 'Custom' }}>
          <button className="flex justify-center items-center h-1/5">
            <img className="w-2/4" src={seasons?.logo_event?.url} />
          </button>
          <button className="px-2">
            <a
              href={`http://qrprint.letsmile.fr/?event=${eventName}${
                label ? `&label=${label}` : ''
              }`}
              target="_blank"
              rel="noreferrer">
              <img src={seasons?.logo_qr_print?.url} />
            </a>
          </button>
          {isTiming && betsLength < 10 ? (
            <div>
              <button
                className="px-2"
                onClick={() => {
                  navigate(`/pronostic?${params.toString()}`, {
                    state: { seasonId, logeId: loge?.id, matchId: seasons.current_match.id }
                  });
                }}>
                <img src={seasons?.logo_pronostic?.url} />
              </button>
              <div className="text-monaco font-montserrat text-center">
                Fin des pronostics {timer.locale('fr').humanize(true)}
              </div>
            </div>
          ) : (
            <button
              className="px-2"
              onClick={() => {
                navigate(`/statistics?${params.toString()}`, {
                  state: { seasonId, warning: isTiming && betsLength >= 10 }
                });
              }}>
              <img src={seasons?.logo_statistics?.url} />
            </button>
          )}

          <button
            className="px-2"
            onClick={() => {
              navigate(`/ranking?${params.toString()}`, { state: { seasonId } });
            }}>
            <img src={seasons?.logo_resultats?.url} />
          </button>
          <a
            className="font-montserrat"
            href={seasons?.reglement?.url}
            target="_blank"
            rel="noreferrer">
            Règlement du concours
          </a>
        </div>
      ) : (
        <Loading src={loadingSvg} />
      )}
    </>
  );
}

export default HomePage;
