import * as queries from '@queries/utils';
import qs from 'qs';
const query = qs.stringify(
  {
    populate: [
      'current_match.club1',
      'current_match.club2',
      'current_match.club1.players',
      'current_match.club2.players',
      'current_match.club1.logo',
      'current_match.club2.logo',
      'logo_event',
      'logo_sponsor',
      'logo_qr_print',
      'logo_pronostic',
      'logo_resultats',
      'logo_statistics',
      'club.font',
      'reglement'
    ]
  },
  {
    encodeValuesOnly: true // prettify URL
  }
);

export const fetchSeason = (id) => queries.getQuery('/api/seasons/' + id, query, {});
export const fetchSeasons = () => queries.getQuery('/api/seasons', query, {});
export const createSeason = (data) => queries.postQuery('/api/seasons', data);
export const updateSeason = (data) => queries.putQuery('/api/seasons', data);
export const deleteSeason = (data) => queries.deleteQuery('/api/seasons', data);
