import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { fetchSeason } from '@queries/Season';
import { useQuery } from '@tanstack/react-query';
import { Loading } from '@components';
import validationLogo from '@assets/validation.png';
import loadingSvg from '@assets/loading.svg';

function Validation() {
  const location = useLocation();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const [isReady, setIsReady] = useState({});

  const { seasonId } = location.state;

  // Get global season parameters
  useQuery(['season'], () => fetchSeason(seasonId), {
    onSuccess: (data) => {
      // Set Font
      const fontFace = new FontFace('Custom', `url(${data?.data?.club?.font?.url})`);
      document.fonts.add(fontFace);
      fontFace.load();

      // Set isReady
      setIsReady(true);
    }
  });

  return isReady ? (
    <div className="flex flex-col justify-around h-screen w-full" style={{ fontFamily: 'Custom' }}>
      <div className="flex flex-col justify-center items-center p-4 ">
        <img className="object-scale-down h-48 w-96" src={validationLogo} alt="validation_logo" />
        <p className="text-2xl text-center font-montserrat mt-4">
          Votre participation a bien été prise en compte
        </p>
      </div>
      <div className="flex flex-col px-4 h-1/6 justify-between">
        <button
          onClick={() =>
            navigate(`/statistics?${params.toString()}`, { state: { seasonId }, warning: false })
          }
          className="mb-5 inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-700 hover:bg-red-800 focus:outline-none w-full justify-center">
          STATISTIQUES
        </button>
        <button
          onClick={() => navigate(`/?${params.toString()}`)}
          className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-700 hover:bg-red-800 focus:outline-none w-full justify-center">
          ACCUEIL
        </button>
      </div>
    </div>
  ) : (
    <Loading src={loadingSvg} />
  );
}

export default Validation;
